<template>
  <div class="window" :id="name">
	<ContentBorder position="boven links"></ContentBorder>
		<slot></slot>
	<ContentBorder position="onder rechts"></ContentBorder>
  </div>
</template>

<script>
import ContentBorder from '../components/contentBorder.vue'

export default {
  name: 'contentWindow',
  props: {
    name:String,
    klass:String
  },
  components: {
  	ContentBorder
  },
}
</script>

<style scoped>
.window {
  background: rgba(241, 116, 5, .7);
  background: rgba(255,255,255,.2);
  margin: 15px;
  position: relative;
  display: inline-block;
  column-break-inside: avoid;
  width: 325px;
  /*min-height: 200px;*/
}
#msg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

