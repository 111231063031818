<template>
  <div>
  	<slot></slot>
  </div>
</template>

<script>

export default {
  name: 'payoffTitle',
  props: {
    
  },
  components: {

  }
}
</script>

<style scoped>
	div{
		padding: 10px;
		padding-left: 16px;
		font-size: 20px;
		font-weight: 400;
		color: #fff;
		line-height: 1;
		text-align: center;
	}

@media only screen and (min-width: 600px) {
	  div{
	    text-align: center;
	  }
	  br{
	    display:none;
	  }
	}
</style>

