<template>
  <contentWindow>
          <contentTitle small="true"> wist je niet </contentTitle>
          <contentText>
           
          Liefhebber van mooi design en functionele creativiteit. 
          <br><br>
          Fiets soms lange afstanden (denk aan naar Parijs niet rondje om de kerk)
          <br><br>
          Ga/ging/ga weer graag naar de film (arthouse-erig)
          <br><br>
          Kijk liever goede documentaire dan een soap.
          

         </contentText>
        </contentWindow>
</template>

<script>
import contentWindow from './contentWindow.vue'
import contentTitle from './contentTitle.vue'
import contentText from './contentText.vue'

export default {
  name: 'extra',
  props: {
    
  },
  components: {
contentWindow,
  	contentTitle,
  	contentText
  }
}
</script>

<style scoped>
	
</style>

