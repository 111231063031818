<template>
 <contentWindow>
          <contentText>
          Mels le Noble <br> 
          Asterkade 21 <br> 
          2106BA Heemstede <br> 06-52635620 <br>    
          me@lenoble.me    </contentText>
        
        </contentWindow> 
</template>

<script>

import contentWindow from './contentWindow.vue'
import contentTitle from './contentTitle.vue'
import contentText from './contentText.vue'

export default {
  name: 'adres',
  props: {
    
  },
  components: {
contentWindow,
  	contentTitle,
  	contentText
  }
}
</script>

<style scoped>
	
</style>

