<template>
  <contentWindow>
          <contentTitle small="true"> in het kort </contentTitle>
          <contentText>
            Ik ben Mels, ik ben flexibel dus in staat om te jongleren tussen verschillende projecten van uiteenlopende opdrachtgevers. 
            <br>
            <br>Ruime ervaring in digitale creatie, vormgeving en animaties.  Multimediale campagnes, animaties, websites-banners en al het andere dat om aandacht vecht op het internet.
          </contentText>

        </contentWindow>
</template>

<script>

import contentWindow from './contentWindow.vue'
import contentTitle from './contentTitle.vue'
import contentText from './contentText.vue'

export default {
  name: 'ik',
  props: {
   
  },
  components: {
  	 contentWindow,
  	contentTitle,
  	contentText
  }
}
</script>

<style scoped>
	
</style>

