<template>
   <contentWindow>
          <contentTitle white="true"> werkgevers </contentTitle>
              <contentText>
                <template v-slot:header>
                  <span>2018 - 2019</span><br>
                  <span>The Valley</span><br><br>
                </template>
                The Valley produceerde voor de nederlandse goede doelen loterijen 3 tot 6 actie en landing sites per maand. Van een aangeleverd Sketch design naar een werkende pixel perfecte site. naast de ontwikkeling van sites hielp ik mee met het maken van de diverse nieuws en bevestigings e-mails die bij de projecten hoorde. 

              </contentText>
               <contentText>
                <template v-slot:header>
                  <span>2014 - 2018</span><br>
                  <span>Pervorm</span><br><br>
                </template>
                Pervorm is een digitaal marketing buro, waarvan de studio display advertenties, animates, IAB banner sets, rich media uitingen produceerde voor klinkende merken als Tommy Hilfiger, Skoda en ING. Naar eigen ontwerp of aangeleverd van de klant.

              </contentText>

              <contentText>
                <template v-slot:header>
                  <span>2005-2014</span><br>
                  <span>Freelance</span><br><br>
                </template>
                Als freelancer was ik kind aan huis bij grote bureaus om te helpen met grote projecten, daarnaast deed ik kleine projecten direct voor een klant. HTML5 en Flash applicaties, games, banner en websites voor merken zoals Philips, Rijksmuseum, ABNAMRO, Albert Heijn, Arke, Canon etc

              </contentText>
        </contentWindow>
</template>

<script>
import contentWindow from './contentWindow.vue'
import contentTitle from './contentTitle.vue'
import contentText from './contentText.vue'


export default {
  name: 'verleden',
  props: {
    
  },
  components: {
  	contentWindow,
  	contentTitle,
  	contentText
  }
}
</script>

<style scoped>
	
</style>

