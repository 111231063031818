<template>
 		 <contentWindow>
          <contentTitle> skills </contentTitle>
           <div v-for="(item, index) in skills">
            <contentText :value="item.value" :last="index == skills.length -1" >{{ item.title }}</contentText>
            
          </div>
          
        </contentWindow>

</template>

<script>

import contentWindow from './contentWindow.vue'
import contentTitle from './contentTitle.vue'
import contentText from './contentText.vue'

export default {
  name: 'skills',
  props: {
    
  },
  components: {
  	contentWindow,
  	contentTitle,
  	contentText
  },
  data: function(){
  		return {
  			skills: [
		        { value:"90", title:"HTML"},
		        { value:"80", title:"CSS "},
		        { value:"70", title:"javascript  "},
		        { value:"90", title:"Photoshop "},
		        { value:"70", title:"Illustrator "},
		        { value:"60", title:"Premiere  "},
		        { value:"70", title:"Sketch & Figma "},
		        { value:"90", title:"Sublime "},
		        { value:"90", title:"greensock "},
		        { value:"60", title:"vue "},
		        { value:"90", title:"animation "}]
  		}
  	}
  }

</script>

<style scoped>
	
</style>

