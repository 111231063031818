<template>
  <div class="paading10" :class="value && !last?'nopaddingbottom':''">
    <slot name="header"></slot>
    <slot></slot>
    <div v-if="value">
      <div class="slider" 
          :data-value="value+ '%'">
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'contentValue',
  props: {
    value: String,
    last:Boolean
  },
  mounted: function(){
   
    if(this.value){
      
      var slider = this.$el.children[0].children[0];
      gsap.set(slider,{"width": 0 })
      gsap.to(slider,1, {"width": this.value + "%"})
    }

  },
  components: {

  },

}
</script>

<style scoped>
  .paading10{
    padding:10px;
  }
  .paading10 > div{
    margin-top: 5px;
    width: 50%;
    display: inline-block;
    margin-left: 20px;
    float: right;
    height: 10px;
    background-color: #432918;
    border: 1px solid #000;
  }
  .slider{
   /* width: 100%;*/
    
    height: 100%;
    background: #fff;
  }
  .nopaddingbottom{
    padding-bottom: 0px;
  }
</style>

