<template>

  	<img :src="url" alt="werk" class="werk" v-on:click="sendToMother"/>

</template>

<script>

export default {
  name: 'contentImage',
  props: {
    src:String,
    link:String,
    msg:String,
    width:String,
    height:String
  },
  components: {

  },
  methods: {
    sendToMother: function (event) {
      this.$emit("isClicked", this)
    }
  },
  data: function(){
    return {
      url: "images/" + this.src
    }
  }
}
</script>

<style scoped>
/*div{
  display: inline-block;

}*/
.werk{
  width: 30%;
  height: 50%;
  margin:2px;
  filter: none;
    -webkit-filter: grayscale(0);
    display: inline-block;
    border: 1px solid #000;

}

img:hover {
    
    filter: grayscale(50%);
    -webkit-filter: grayscale(100%);
}
</style>

