<template>
  <div id="app">

    <pageTitle class="content" v-if="!show_work">Mels <br> <blr>le Noble</blr></pageTitle>
    <payoffTitle class="content" v-if="!show_work">Zo veel inspiratie <br>Zo weinig tijd</payoffTitle>
    

    <preview 
            id="preview"
            v-if="show_work" 
            @isClicked = "exit_preview" 
            :link= "preview_link"

            > 
              
    </preview>

    <div v-if="!debug&& !show_work">
      <skills /> 
     
      <verleden />

    </div>

    <div class="content" v-if="!show_work">
    <pageColumns>

  
<skills /> 
<contentWindow>
          <contentTitle> Werk </contentTitle>
          <contentImages>



<contentImage 
  src= "_0000_rijks.png"  
  @isClicked = "show_preview" 
  link = "https://vimeo.com/107035330"/>

    <contentImage src= "evi_slim.png" 
  @isClicked = "show_preview"
  link="evi_slim.mp4"
  />

<contentImage 
  src= "_0006_abnamro.png" 
  @isClicked = "show_preview" 
  link = "abnamro.mp4"/>
 
 <contentImage 
  src= "_0012_jumbo.png" 
  @isClicked = "show_preview" 
  link="jumbo-juigpak.mp4"/> 

    <contentImage 
  src= "_0002_arke.png" 
  @isClicked = "show_preview"
  link="dit is nog (?) niet te zien :-( "
  />

  <contentImage 
  src= "_0004_heineken.png" 
  @isClicked = "show_preview"
  link="dit is nog (?) niet te zien :-( "
  />

  <contentImage 
    src= "bmw.png" 
    @isClicked = "show_preview"
     link="bmw.mp4"
    />

  <contentImage 
    src= "_0014_hilfiger.png" 
    @isClicked = "show_preview"
    link="tommyH.mp4"
    />

    <contentImage 
    src= "_0001_AH.png" 
    @isClicked = "show_preview"
    link="dit is nog (?) niet te zien :-( "
    />

    <contentImage 
    src= "_0015_jumbo-uitjes.png" 
    @isClicked = "show_preview"
    link="jumbouit.mp4"
    />
    
    <contentImage 
    src= "vara.png" 
    @isClicked = "show_preview"
    link="dit is nog (?) niet te zien :-( "
    />


  <contentImage 
  src= "_0011_vw.png" 
  @isClicked = "show_preview"
  link="vwbus.mp4"/>



  <contentImage 
    src= "_0008_mediamarkt.png" 
    @isClicked = "show_preview"
    link="mediamarkt.mp4"
    />

      <contentImage src= "skoda.png" 
  @isClicked = "show_preview"
  link="skoda_instap.mp4"
  />
  



  <contentImage 
    src= "kinderjuigpak.png" 
    @isClicked = "show_preview"
    link="kinderjuigpak.mp4"/>
  

    </contentImages>

</contentWindow>

<verleden />
<div v-if="!debug && !show_work">
  <ik  />
     <!--  <extra /> -->
      <adres /> 
</div>


    

    </pageColumns>
  </div> 
  <backGround class="content" v-if="!show_work"/>
  </div>
</template>

<script>
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

import pageTitle from './components/pageTitle.vue'
import payoffTitle from './components/payoffTitle.vue'
import backGround from './components/BackGround.vue'
import pageColumns from './components/pageColumns.vue'
import contentWindow from './components/contentWindow.vue'
import ContentBorder from './components/contentBorder.vue'
import contentTitle from './components/contentTitle.vue'
import blr from './components/bolderText.vue'
import contentText from './components/contentText.vue'
import contentImages from './components/contentImages.vue'
import contentImage from './components/contentImage.vue'
import preview from './components/preview.vue'

import skills from './components/skills.vue'
import ik from './components/ik.vue'
import verleden from './components/verleden.vue'
import extra from './components/extra.vue'
import adres from './components/adres.vue'

export default {
  name: 'App',
  components: {
    pageTitle,
    payoffTitle,
    backGround,
    pageColumns,
    contentWindow,
    ContentBorder,
    contentTitle,
    contentText,
    contentImages,
    contentImage,
    blr,
    preview,
    skills,
    ik,
    verleden,
    extra,
    adres
  },
  mounted() {
    // (document.querySelectorAll('.window')).forEach(function(e,i){
    //   e.style.height= getRandomInt(100,500)+ "px"
    // })
  },
  data: function(){
    return {
      debug: true,
      show: true,
      noshow: false,
      show_work: false,
      preview_text: "preview text",
      preview_text_default: "hier komt mijn werk te staan, maar zoals je ziet daar ben ik nog mee bezig",
      preview_iframe: false,
      preview_width: "200px",
      preview_height: "200px",
      preview_link:"deze",


    }

  },
  methods:{
      show_preview: function(e){
      this.preview_link = e.link;
      this.show_work = true;
      },
      exit_preview: function(){this.show_work = false}


  //        gsap.to('.content', 1 ,{
  //         alpha:0, 
  //         onComplete:function(){this.show_work = true}.bind(this)})

   }
}

</script>

<style>
body {
  background-color:#F17405;
  height:100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
}
.box{
  width: 300px;
  height: 200px;
  background-color: #023;
  color: #fff;
  margin-bottom: 10px;
}

@media screen and (max-width: 750px) { 
.box{
  width:100%;
  }
}
</style>
