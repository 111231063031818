<template>
  <span class="bolder">
  	<slot></slot>
  </span>
</template>

<script>

export default {
  name: 'bolderText',
  props: {
    
  },
  components: {

  }
}
</script>

<style scoped>
	.bolder{
		font-weight: 800;
	}

	
</style>

