<template>
  	<div class="preview" @link="addtask" >
      <slot></slot>
      <div id="target"></div>
  	   <div v-if="this.link.indexOf('mp4') == -1">{{ link }}</div>
  	<div  v-on:click="sendToMother" class="close"></div>
  	</div>
</template>

<script>

export default {
  name: 'preview',
  props: {
    src:String,
    link:String,
  },
  components: {

  },
  methods: {
    addtask: function(e){
      console.log(e)
    },
  	 sendToMother: function (event) {
  	 	this.$emit("isClicked", {link:this.link})
  		//this.$emit("isClicked", {link:this.link, mgs:this.msg})
      console.log("close")
    },
    removeVideo: function(){
      var v = this.$el.querySelector('#video')
      if(v){
        this.$el.querySelector('#video').remove()
      }


    },
    createvideo: function (){
      this.removeVideo()
      
      name = this.url
      var main= this.$el

      var video = document.createElement("video");
      main.appendChild(video);
      video.id = "video"

      video.onloadeddata =  function(e){ 
        gsap.to(main, .5, {
            width:video.offsetWidth,
            height:video.offsetHeight
          })
        // main.style.width = video.offsetWidth + "px"
        // main.style.height = video.offsetHeight + "px"
        video.play() 
      }
      video.addEventListener('ended', function () {
        this.currentTime = 0;
        this.play();
      }, false);

      video.setAttribute('src', name );
      video.load();
    }
  },
  mounted(){
   
    // if(this.link.indexOf('mp4') == -1){
    //   if(this.link.indexOf('http://') !== -1){
    //     return open(this.link, "_blank")
    //   }
    //   return 
    

    if(this.link.indexOf('mp4') !== -1){ 
      this.createvideo() 
    }
    if(this.link.indexOf('http') !== -1){
      console.log("link openen")
      open(this.link, "_blank")
    }
    return
  
    // window.m = this
    // console.log(this)
  },  
  data: function(){
    return {
    	info: this.text,
      url: "https://lenoble.me//werk/videos/" + this.link
    }
  }
}
</script>

<style scoped>

.preview{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	height: 200px;
	border: 1px solid #fff;

	display: flex;
	justify-content: center;
	align-items: center;
}
.close {
  position: absolute;
    left: 100%;
    top: 50%;
  width: 32px;
  height: 32px;
  opacity: .5;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
iframe{
  position: absolute;
  left:0px;
  top:0px;
  /*border: 0px solid #000;*/
  width: 100%;
  height: 100%;
}
</style>
