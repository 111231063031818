<template>
  <div id="columns">
  	<slot></slot>

  </div>
</template>

<script>

export default {
  name: 'pageColumns',
  props: {
    
  },
  components: {

  }
}
</script>

<style scoped>
#columns {
  column-width: 320px;
  column-gap: 15px;
  width: 90%;
  max-width: 1100px;
  margin: 50px auto;
}
@media screen and (max-width: 750px) { 
  #columns { column-gap: 0px; }
  #columns figure { width: 100%; }
  #columns{
    margin: unset;
  }
}
</style>

