<template>
  <div>
  <div v-for="value in pos()" class="border" :class="value">
  </div>
</div>

</template>

<script>

export default {
  name: 'ContentBorder',
  props: {
    position:String
  },
  components: {

  },
  methods:{
  	pos: function(){
  		var classes = []
      if(!this.position){return classes}
      this.position.split(" ").forEach(function(e,i){
        classes.push(e)
      })
  		return classes
  	}
  }
}
</script>

<style scoped>
	.border{
  position: absolute;
  background-color: #fff;
  -webkit-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform-origin: center top;
    -ms-transform-origin: center top;
    transform-origin: center top;
    -webkit-transition: transform 0.3s 0.3s;
    -o-transition: transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
}
.boven{
  width: 100%;
  right: 0;
  top: 0;
  height: 2px;
}
.links{
  width: 2px;
    left: 0;
    top: 0;
    height: 100%;
}
.rechts{
    width: 2px;
    right: 0;
    top: 0;
    height: 100%;
}
.onder{
    width: 100%;
    right: 0;
    bottom: 0;
    height: 2px;
}
</style>

