<template>
  <div>
  	<slot></slot>
  </div>
</template>

<script>

export default {
  name: 'pageTitle',
  props: {
    
  },
  components: {

  }
}
</script>

<style scoped>
	div{
		padding: 10px;
		font-size: 60px;
		font-weight: 400;
		color: #fff;
		line-height: .8;
    text-align: center;
	}
@media only screen and (min-width: 600px) {
  div{
    text-align: center;
  }
    br{
      display:none;
    }
  }
</style>

