<template>
  <div class="images">
    <div ></div>
  	<slot></slot>
     

  </div>
</template>

<script>

export default {
  name: 'contentImages',
  components: {
  },
  props: {
    
  },
  components: {

  },
  methods:{
  
  },
  data: function(){
    return {}
  }

}
</script>

<style scoped>

.images{
	padding: 10px;

    float: left;

	/*text-align: center;
	width: 100%;
	height: 100%;
	border: 1px solid #000;
	position: relative;*/
}
.images::after {
  content: "";
  clear: both;
  display: table;
}
	
</style>

