<template>
	<div :class="classes()">
		<!-- <img src="../assets/hoofd.svg" width="18px" height="18px"> -->
  		<slot>hello</slot>
  		<ContentBorder position="onder" v-if="showBorder()"></ContentBorder>
  	</div>
</template>

<script>
import ContentBorder from '../components/contentBorder.vue'

export default {
  name: 'ContentTitle',
  props: {
    noborder:String,
    small:String,
    white:String
  },
  data: function(){
    return {
      wit: "white "
    }
  },
  components: {
  	ContentBorder
  },
  methods:{
  	showBorder: function(){
  		if(this.noborder === "true" || this.small === "true" || this.white=== "true"){
  			return false
  		}
  		return true
  	},
  	paddingLeft: function(){
  		return this.noborder === "true"? "padding_left_0 ": "";
  	},
    isSmall: function(){
      return this.small === "true"? "small white ": "";
    },
    isWhite: function(){
      return this.white === "true" ? "white " : "";
    },
    classes: function(){
      return "title " + this.paddingLeft() + this.isSmall() + this.isWhite()
    }
  }
}
</script>

<style scoped>
	.title{
		position: relative;
		font-size: 18px;
		padding-top:9px;
		padding-bottom:9px;
		padding-left: 9px;
		padding-right: 9px;
    color: #fff;
	}
	.padding_left_0{
		padding-left: 0px;
	}
  .white{
    background-color: #fff;
    color: #000;
  }
  .small{
    width: 50%;
  }

</style>

