<template>
  <div class="background masked">
   <canvas id="canvas" width="672px" height="672px"></canvas>
  </div>
</template>

<script>
export default {
  name: 'BackGround',
  props: {
    msg: String
  },
  mounted() {
      // let recaptchaScript = document.createElement('script')
      // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      // document.head.appendChild(recaptchaScript)
      this.init()
    },
    methods: {
    	init:function(){
			this.canvas = document.getElementById('canvas');
			this.ctx = canvas.getContext('2d');
			this.create();
			this.draw();

    	},
    	create:function(){
			for(var i=0;i<100;i++){
			  this.cirkels[i]= {};
			  this.cirkels[i].x = Math.random()*700;
			  this.cirkels[i].y = Math.random()*700;
			  this.cirkels[i].r = Math.random()*10;
			  this.cirkels[i].a = Math.random()*1;
			  this.cirkels[i].s = this.cirkels[i].r *.1;
			}
    	},
    	clear:function(){
    		this.ctx.clearRect(0, 0, 800, 800);
    	},
    	draw:function(){
    		this.clear();
    		this.cirkels.forEach(function(e,i){
    			this.style(e.a);
    			this.ctx.beginPath();
          		this.circle(e.x, e.y, e.r);
				// this.ctx.closePath();
         		this.ctx.fill();
            e.y -= (e.r/5)
            if(e.y<0){e.y=900}
    		}.bind(this))
        window.requestAnimationFrame(this.draw.bind(this));

    	},
    	style:function(alpha){
    		this.ctx.fillStyle = "rgba(241,116,5,"+alpha+")"
    	},
    	circle:function(x,y,r){
    		this.ctx.arc(x, y, r, 0, 2 * Math.PI);
    	}
    },
    data: function () {
      return {
        cirkels: []
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.masked {
  position: fixed;
  width: 672px;
  height: 672px;
  background: #000000;
  background-color: #F17405;
    mask-image: url('../assets/hoofd.svg');
  -webkit-mask-image: url('../assets/hoofd.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  bottom:0;
  right: -55px;
  z-index: -1;
}
canvas{

  background: #000;
}
@media only screen and (max-width: 1000px) {
  .masked{
    width: 300px;
    height: 300px;
    right: -20px;
  }
}
@media only screen and (max-width: 600px) {
  .masked{
    width: 200px;
    height: 200px;
    right: -20px;
  }
}

</style>
